import { Button, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faUndo,
  faBookmark,
} from "@fortawesome/free-solid-svg-icons";
import "./item.css";

type ItemProps = {
  item: {
    id: string;
    description: string;
    state: string;
    link?: string | undefined;
    image?: string | undefined;
    priority?: number | undefined;
  };
  promiseKey?: string | undefined;
  action: "delete" | "promise";
  callback: (id: string, promiseKey?: string) => void;
};

export const Item = ({ item, action, promiseKey, callback }: ItemProps) => {
  const cardContent = (
    <>
      {item.link ? (
        <a
          href={item.link}
          target="_blank"
          rel="noopener noreferrer"
          className="card-link"
        >
          <div className="image-container">
            {item.image ? (
              <Card.Img
                variant="top"
                src={item.image}
                alt="Item"
                className="item-image"
              />
            ) : (
              <div className="item-placeholder"></div>
            )}
            {item.state === "Promised" && action !== "delete" && (
              <div className="overlay">Promised</div>
            )}
          </div>
        </a>
      ) : (
        <div className="image-container">
          {item.image ? (
            <Card.Img
              variant="top"
              src={item.image}
              alt="Item"
              className="item-image"
            />
          ) : (
            <div className="item-placeholder"></div>
          )}

          {item.state !== "Wished" && (
            <div className="overlay">{item.state}</div>
          )}
        </div>
      )}

      <Card.Body className="card-body">
        <Card.Title className="item-details">{item.description}</Card.Title>
        {item.priority && <Card.Text className="item-state">
    {Array.from({ length: Math.min(item.priority, 5) }).map((_, index) => (
      <span key={index}>🤞</span>
    ))}
  </Card.Text>}
        {action === "delete" ? (
          <Button
            variant="danger"
            onClick={() => callback(item.id)}
            className="delete-button"
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        ) : (
          <>
            {promiseKey && item.state === "Promised" && (
              <Button
                variant="primary"
                onClick={() => callback(item.id, promiseKey)}
                className="promise-button"
              >
                <FontAwesomeIcon icon={faUndo} />
              </Button>
            )}
            {item.state === "Wished" && (
              <Button
                variant="primary"
                onClick={() => callback(item.id)}
                className="promise-button"
              >
                <FontAwesomeIcon icon={faBookmark} />
              </Button>
            )}
          </>
        )}
      </Card.Body>
    </>
  );

  return (
    <Card className={`item-card ${item.link ? "clickable" : ""}`}>
      {cardContent}
    </Card>
  );
};
